<template>
  <div class="features-and-benefits-page-wrapper">
    <div class="features-and-benefits-page">
      <div class="column">
        <div>
          <div class="title">Account Features</div>
          <ul>
            <li class="bt">
              <span>Overdraft Facility</span>
              <i :class="bankDetails.accountFeatures.overdraftFacility ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Dedicate Relationship Manager</span>
              <i :class="bankDetails.accountFeatures.dedicatedRelationshipManager ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
          </ul>
        </div>
        <div>
          <div class="title">Ways to Pay</div>
          <ul>
            <li class="bt">
              <span>ATM</span>
              <i :class="bankDetails.waysToPay.atm ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Debit card</span>
              <i :class="bankDetails.waysToPay.debitCard ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Credit card</span>
              <i :class="bankDetails.waysToPay.creditCard ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Charge card</span>
              <i :class="bankDetails.waysToPay.chargeCard ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Cheque</span>
              <i :class="bankDetails.waysToPay.cheque ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Direct Debit</span>
              <i :class="bankDetails.waysToPay.directDebit ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Standing Order</span>
              <i :class="bankDetails.waysToPay.standingOrder ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Apple Pay</span>
              <i :class="bankDetails.waysToPay.applePay ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Google Pay</span>
              <i :class="bankDetails.waysToPay.googlePay ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Samsung Pay</span>
              <i :class="bankDetails.waysToPay.samsungPay ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="column">
        <div>
          <div class="title bb">Ways to Bank</div>
          <ul>
            <li>
              <span>In branch</span>
              <i :class="bankDetails.waysToBank.inBranch ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Online</span>
              <i :class="bankDetails.waysToBank.online ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Mobile app</span>
              <i :class="bankDetails.waysToBank.mobileApp ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Telephone</span>
              <i :class="bankDetails.waysToBank.telephone ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
            <li class="bt">
              <span>Post Office</span>
              <i :class="bankDetails.waysToBank.postOffice ? 'icon-status-checked' : 'icon-status-unchecked'"></i>
            </li>
          </ul>
        </div>
        <div>
          <div class="title bb">Additional Account Features</div>
          <div v-for="(description, index) in bankDetails.bankDataSwitchingService.description" :key="index">
            <div class="description-header" v-if="description.header">{{ description.header }}</div>
            <p class="description-body" v-if="description.body">{{ description.body }}</p>
          </div>
        </div>
      </div>
      <div class="column">
        <div>
          <div class="title bb">Additional Information</div>
          <div v-for="(description, index) in bankDetails.additionalWaysToBank.description" :key="index">
            <div class="description-header" v-if="description.header">{{ description.header }}</div>
            <p class="description-body" v-if="description.body">{{ description.body }}</p>
          </div>
        </div>
        <div class="banking-service-quality">
          <div class="title bb">
            Business Banking Service
            <br />Quality Great Britain
            <div class="meta ui subtitle">Independent Service Quality Survey Results</div>
          </div>
          <div class="overall-service" v-if="bankDetails.businessBankingServiceQuality.isPending">
            <span class="percentage">Pending</span>
            <div class="meta ui subtitle">
              Coming Nov 2019 - Starling Bank will be included in the next Independent Service Quality Survey; the results of which will be released in November 2019.
            </div>
          </div>
          <template v-else>
            <div class="overall-service">
              <span class="percentage m-b-10" v-if="!['STARLING BANK', 'Revolut'].includes(bankDetails.bankName)"
                >{{ bankDetails.businessBankingServiceQuality.overallService }}%</span
              >
              <span class="m-b-10" v-else>N/A</span>
              <div class="meta ui subtitle">
                Overall service
                <br />quality
              </div>
            </div>
            <ul>
              <li>
                <span class="percentage m-b-5" v-if="!['STARLING BANK', 'Revolut'].includes(bankDetails.bankName)"
                  >{{ bankDetails.businessBankingServiceQuality.onlineAndMobileBankingService }}%</span
                >
                <span class="m-b-10" v-else>N/A</span>
                <div class="meta ui subtitle">Online &amp; Mobile Banking Services</div>
              </li>
              <li>
                <span class="percentage m-b-5" v-if="!['STARLING BANK', 'Revolut'].includes(bankDetails.bankName)"
                  >{{ bankDetails.businessBankingServiceQuality.relationshipAccountManagement }}%</span
                >
                <span class="m-b-10" v-else>N/A</span>
                <div class="meta ui subtitle">Relationship/Account Management</div>
              </li>
              <li>
                <span class="percentage m-b-5" v-if="!['The Co-operative Bank', 'STARLING BANK', 'Revolut', 'Metro Bank'].includes(bankDetails.bankName)"
                  >{{ bankDetails.businessBankingServiceQuality.smeOverdraftAndLoanServices }}%</span
                >
                <span class="m-b-10" v-else>N/A</span>
                <div class="meta ui subtitle">
                  SME Overdraft and Loan Services
                  <popover
                    placement="top"
                    trigger="hover click focus"
                    class="popover"
                    v-if="bankDetails.bankName === 'The Co-operative Bank' || bankDetails.bankName === 'Metro Bank'"
                  >
                    <span class="icon-status-info"></span>
                    <template #popover
                      >The services are provided but the results for the service are not displayed as fewer than 100 of their customers provided an eligible score.</template
                    >
                  </popover>
                </div>
              </li>
              <li>
                <span class="percentage m-b-5" v-if="!['STARLING BANK', 'Revolut'].includes(bankDetails.bankName)"
                  >{{ bankDetails.businessBankingServiceQuality.serviceInBranchesAndBusinessCenters }}%</span
                >
                <span class="m-b-10" v-else>N/A</span>
                <div class="meta ui subtitle">Service in Branches and Business Centres</div>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
    <p class="disclaimer">
      Swoop has prepared your personalised comparison summary, using transactions from your business current account over the past 12 months to predict the banking fees you are
      likely to incur with each of the banks we compare, if you continue to make the same number and type of transactions. Swoop does not compare credit or lending products
      including Overdrafts, Credit Cards or Loan accounts. The comparison is based on your historic banking usage and the published tariff data of the banks compared. The results
      provide an indication of potential savings. Customers must consider their current and future banking needs and seek independent advice where necessary. Fees may change and
      business current accounts may be removed from the comparison at any time.
    </p>
  </div>
</template>

<script>
export default {
  name: 'FeaturesAndBenefits',
  props: {
    bankDetails: {
      type: Object,
      required: true
    }
  },
  methods: {
    replaceUrlText(string) {
      const regExpUrl = /(?:https?:\/\/)?([-a-zA-Z0-9.]{2,256}\.[a-z]{2,10})\b(?:\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi
      const regExpProtocol = /https?:\/\//i
      const uri = string.match(regExpUrl)
      const check = uri ? uri.toString().match(regExpProtocol) : false
      return !regExpUrl
        ? string
        : check // eslint-disable-next-line indent
        ? string.replace(regExpUrl, `<a href=${uri} target="_blank" rel="noopener">$1</a>`) // eslint-disable-next-line indent
        : string.replace(regExpUrl, `<a href="http://${uri}" target="_blank" rel="noopener">$1</a>`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/swoop/variables';

.features-and-benefits-page-wrapper {
  padding: 50px 35px 40px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-wrap: wrap;
    padding: 1rem;
  }
}
.features-and-benefits-page {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 25px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-wrap: wrap;
    // padding: 30px 15px 0;
    padding: 0rem;
  }
  /deep/ {
    a {
      text-decoration: underline;
    }

    .popover {
      .icon-status-info {
        &::before {
          font-size: 10px;
        }
      }
    }
  }
  .column {
    flex-basis: 33.333333%;
    margin-left: 15px;
    margin-right: 15px;
    @media only screen and (max-width: $breakpoint-sm-max) {
      flex-basis: 100%;
    }
    > div:not(:last-child) {
      margin-bottom: 50px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        margin-bottom: 30px;
      }
    }
    .title {
      color: var(--color-primary-500);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      padding: 12px 0;
      .sub-title {
        color: $color-main;
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        padding-top: 6px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 20px;
      color: $color-main;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        padding: 12px 0;
      }
    }
    p {
      padding: 10px 0;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: $color-main;
    }
    .overall-service {
      // color: $color-main;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 7px;
      span.percentage {
        color: $color-primary;
        display: block;
        font-size: 32px;
        line-height: 39px;
      }
      .meta.ui.subtitle {
        color: var(--color-primary-400);
        font-size: 12px;
        letter-spacing: -0.5px;
        line-height: 16px;
        text-align: center;
      }
    }
    .banking-service-quality {
      border: $default-border-color;
      border-radius: 8px;
      box-shadow: $default-box-shadow;
      padding: 24px;
      .title,
      .overall-service {
        text-align: center;
      }
      span.percentage {
        font-size: 36px;
        line-height: 36px;
      }
      .meta.ui.subtitle {
        color: var(--color-primary-400);
        font-size: 12px;
        letter-spacing: -0.5px;
        line-height: 16px;
        text-align: center;
      }
      ul {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        text-align: center;
        li {
          font-size: 14px;
          line-height: 16px;
          display: block;
          padding: 15px;
          flex-basis: 50%;
          max-width: 50%;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          .subtitle {
            font-size: 0.85em;
          }
          .percentage {
            display: block;
            font-size: 24px;
            line-height: 36px;
            color: var(--color-primary-500);
          }
        }
      }
      > .title {
        margin-bottom: 24px;
        padding-bottom: 24px;
      }
    }
  }
  .description-header {
    padding-top: 15px;
    text-decoration: underline;
  }
}
</style>
